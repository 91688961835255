import * as Klaro from 'klaro/dist/klaro-no-css';

const config = {
    version: 1,
    testing: false,
    elementID: 'klaro',
    styling: {
        theme: [
            'right',
            // 'light',
            // 'top',
            // 'wide'
        ],
    },
    noAutoLoad: false,
    htmlTexts: false,
    embedded: false,
    groupByPurpose: false,
    storageMethod: 'cookie',
    storageName: 'klaro',
    // cookieDomain: '.example.com',
    cookieExpiresAfterDays: 365,
    default: false,
    mustConsent: false,
    acceptAll: true,
    hideDeclineAll: false,
    hideLearnMore: false,
    noticeAsModal: false,
    disablePoweredBy: false,
    // lang: 'de',

    translations: {
        // zz = default translations
        zz: {},
        en: {
            privacyPolicyUrl: '/privacy-policy',
            purposes: {
                general: 'Basic functionality and security',
                multimedia: 'External content',
                analytics: 'Statistics',
            },
        },
        de: {
            privacyPolicyUrl: '/datenschutz',
            consentNotice: {
                changeDescription:
                    'Es gab Änderungen seit Ihrem letzten Besuch, bitte aktualisieren Sie Ihre Auswahl.',
                description:
                    'Ihre Privatsphäre ist uns wichtig. Deswegen verwenden wir ohne Ihre Zustimmung keine externen Services oder erfassen persönliche Daten. Sie können dem Einsatz von externen Services (insbesondere für die Anzeige externer Inhalte und zur Erfassung anonymisierter Besucherstatistiken) hier zustimmen, diesen ablehnen oder individuelle Einstellungen treffen.',
                learnMore: 'Einstellungen',
            },
            consentModal: {
                title: 'Ihre Privatsphäre-Einstellungen',
                description:
                    'Hier können Sie einsehen und anpassen, welche persönlichen Daten wir erfassen und welche externen Services wir nutzen.',
                privacyPolicy: {
                    name: 'Datenschutzerklärung',
                    text: 'Weitere Details finden Sie in unserer {privacyPolicy}.',
                },
            },
            ok: 'Zustimmen',
            save: 'Speichern',
            decline: 'Ablehnen',
            close: 'Schließen',
            acceptSelected: 'Auswahl speichern',
            acceptAll: 'Allen zustimmen',
            app: {
                disableAll: {
                    title: 'Alle Anwendungen umschalten',
                    description:
                        'Mit dieser Schaltfläche können Sie alle Anwendungen auf einmal aktivieren bzw. deaktivieren.',
                },
                optOut: {
                    title: '(Opt-Out)',
                    description:
                        'Diese Anwendung wird standardmäßig geladen (Sie können sie aber deaktivieren)',
                },
                required: {
                    title: '(immer notwendig)',
                    description: 'Diese Anwendung wird immer benötigt.',
                },
                purposes: 'Verwendungszwecke',
                purpose: 'Verwendungszweck',
            },
            poweredBy: 'Umgesetzt mit Klaro!',

            purposes: {
                general: 'Basisfunktionalität und Sicherheit',
                multimedia: 'Externe Inhalte',
                analytics: 'Statistik',
            },
        },
    },

    // This is a list of third-party services that Klaro will manage for you.
    services: [
        {
            name: 'klaro',
            default: true,
            required: true,
            purposes: ['general'],
            cookies: ['klaro'],
            translations: {
                de: {
                    title: 'Privatsphäre-Einstellungen',
                    description:
                        'In dem klaro-Cookie werden Iher hier getroffenen Privatsphäre-Einstellungen gespeichert.',
                },
                en: {
                    title: 'Privacy settings',
                    description: 'This cookie is used to save your privacy settings.',
                },
            },
        },
        {
            name: 'csrf',
            default: true,
            required: true,
            purposes: ['general'],
            cookies: ['CRAFT_CSRF_TOKEN'],
            translations: {
                de: {
                    title: 'CSRF-Schutz',
                    description: 'Verhindert gefälsche Anfragen von externen Seiten.',
                },
                en: {
                    title: 'CSRF protection',
                    description: 'Prevent forged requests from external sites.',
                },
            },
        },
        {
            name: 'matomo',
            default: true,
            required: true,
            purposes: ['analytics'],
            cookies: [/^_pk_.*$/],
            translations: {
                de: {
                    title: 'Matomo',
                    description:
                        'Wir verwenden die Open-Source-Software Matomo, um anonyme Besucherstatistiken zu sammeln. Hierbei werden keine privaten Daten erfasst.',
                },
                en: {
                    title: 'Matomo',
                    description:
                        'We use the open source software Matomo to collect visitor statistics. This doesn’t collect any personal data.',
                },
            },
        },
        {
            name: 'YouTube',
            purposes: ['multimedia'],
            translations: {
                de: {
                    title: 'YouTube',
                    description: 'Diese Option aktiviert eingebettete YouTube-Videos.',
                },
                en: {
                    title: 'YouTube',
                    description: 'This option activates embedded YouTube videos.',
                },
            },
        },
        {
            name: 'Vimeo',
            purposes: ['multimedia'],
            translations: {
                de: {
                    title: 'Vimeo',
                    description: 'Diese Option aktiviert eingebettete Vimeo-Videos.',
                },
                en: {
                    title: 'Vimeo',
                    description: 'This option activates embedded Vimeo videos.',
                },
            },
        },
    ],
};

// Initialize klaro with the given config.
Klaro.setup(config);

// Provide global access to the klaro instance and its config.
window.klaro = Klaro;
window.klaroConfig = config;

// Provide a utility class for links to open the klaro modal.
const managePrivacyPreferencesLinks = Array.from(document.querySelectorAll('.klaro-show'));
managePrivacyPreferencesLinks.forEach(link =>
    link.addEventListener('click', e => {
        e.preventDefault();
        window.klaro.show();
    }),
);
